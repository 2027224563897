@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/8503aff4e17b160c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/e93971bf518cf9b5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/79be1aa52d862195-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/9713a98d0cfd95bf-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/4e605673de87f3f3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/d4ca2d6ea6e692dd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/1ad48b753d8196af-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/56b8ba57ec9750a7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/1d9e6430c78d0f57-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/bd20900dbf9da7bd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/74834d23e7ecf419-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/16e42cc690b8205b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/6afb7f47a2fd0e9c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/fa8aceb8138527b6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/6c219516a9d8fbb4-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/7d31904d1677786f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/8a5bc9e0a63b764f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__MetropolisFont_ccd539';
src: url(/_next/static/media/24781e398b5c9b95-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__MetropolisFont_Fallback_ccd539';src: local("Arial");ascent-override: 77.00%;descent-override: 19.85%;line-gap-override: 0.00%;size-adjust: 103.25%
}.__className_ccd539 {font-family: '__MetropolisFont_ccd539', '__MetropolisFont_Fallback_ccd539'
}.__variable_ccd539 {--font-metropolis: '__MetropolisFont_ccd539', '__MetropolisFont_Fallback_ccd539'
}

